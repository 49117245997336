import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import { CharacterAnimation } from "../CharacterAnimation.jsx";
import "../style.css";

import products from "./products.json";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: "50px !important",
    paddingBottom: "50px !important",
  },
  image: {
    maxWidth: "100% !important",
    borderRadius: "10px !important",
  },
  question: {
    fontSize: "35px !important",
    fontWeight: "800 !important",
    lineHeight: "45px !important",
  },
  radioGroup: {
    marginTop: "20px !important",
    marginBottom: "15px !important",
  },
  label: {
    fontSize: "17px !important",
    marginLeft: "5px !important",
    fontWeight: "400 !important",
  },
  containerQuestion: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
}));

export const Question3 = ({ formData, setFormData, navigation }) => {
  const { question1, question2, question3 } = formData;
  const [isAnswered, setIsAnswered] = useState(false);
  const [matiere, setMatiere] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    const filteredProducts = products.data.filter(
      (product) =>
        product.lieupose === question1 &&
        product.surquoi === question2 
    );
    const uniqueMatiere = [...new Set(filteredProducts.map((item) => item.matiere))];
    setMatiere(uniqueMatiere);
  }, [question1, question2]);

  useEffect(() => {
    setIsAnswered(question3 !== "");
  }, [question3]);

  const handleNext = () => {
    navigation.next();
  };

  return (
    <Container maxWidth="md" className={classes.containerQuestion}>
      <div className={classes.root}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} sm={6}>
            <img
              src="question3.jpg"
              alt="Question 3"
              className={classes.image}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              className={classes.question}
            >
              Quelle est la propriété dont vous avez besoin ?
            </Typography>
            <RadioGroup
              className={classes.radioGroup}
              aria-label="question3"
              name="question3"
              value={question3}
              onChange={(e) => {
                setFormData({ ...formData, question3: e.target.value });
              }}
            >
              {matiere.length > 0 ? (
                <>
                  {matiere.filter(item => item !== null).map((matiere) => (
                    <FormControlLabel
                      key={matiere}
                      classes={{ label: classes.label }}
                      value={matiere}
                      control={<Radio />}
                      label={matiere}
                    />
                  ))}
                </>
              ) : (
                <>
                  <Typography style={{ marginTop: '20px', fontSize: '17px', fontWeight: '600', color: '#cc0000' }}>
                    Nous n'avons pas de suggestion pour cette question.
                  </Typography>
                  <Typography style={{ marginBottom: '20px', fontSize: '17px', fontWeight: '600', color: '#cc0000' }}>
                    Veuillez passer à la question suivante.
                  </Typography>
                </>
              )}
            </RadioGroup>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="contained"
                fullWidth
                style={{ marginTop: "1rem", marginRight: "1rem" }}
                onClick={navigation.previous}
              >
                Précédent
              </Button>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                style={{ marginTop: "1rem" }}
                disabled={!isAnswered}
                onClick={handleNext}
              >
                Suivant
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      {/*<CharacterAnimation
        characterImage="/character2.png"
        characterText="C'est bientôt fini ! après cette question je vais te donner ma recommandation"
      />*/}
    </Container>
  );
};
