import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Typography, Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex !important",
        flexDirection: "column !important",
        alignItems: "center !important",
        justifyContent: "center !important",
        height: "100vh !important",
        marginRight: "auto !important",
        marginLeft: "auto !important",
    },
    subheading: {
        fontSize: "1.4rem !important",
        fontWeight: "700 !important",
    },
    button: {
        marginTop: "20px !important",
    },
}));

export const NotFoundPage = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleGoToHomePage = () => {
        navigate("/");
    };

    return (
        <div className={classes.root}>
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                <img src="404.jpg" alt="erreur 404" style={{ width: 'auto', height: '325px' }} />
            </div>
            <Typography className={classes.subheading} variant="h5">
                Oups, la page que vous recherchez n'existe pas.
            </Typography>
            <Button onClick={handleGoToHomePage} variant="contained" className={classes.button}>
                Revenir en lieu sûr
            </Button>
        </div>
    );
};
