import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from "@mui/icons-material/ListAlt";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import ListItemButton from '@mui/material/ListItemButton';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';

export const SideMenu = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // modifier la valeur initiale de menuOpen à false

  useEffect(() => {
    const isAdminLocal = localStorage.getItem("isAdmin");
    if (isAdminLocal === "true") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);

  const menuItems = [
    { label: <span style={{ color: "white", fontSize:"18px" }}>Accueil</span>, icon: <HomeIcon style={{ color: "white" }} fontSize="large" />, link: "/home" },
    { label: <span style={{ color: "white", fontSize:"18px"  }}>Matrice</span>, icon: <ListAltIcon style={{ color: "white" }} fontSize="large" />, link: "/form" },
    { label: <span style={{ color: "white", fontSize:"18px"  }}>Matériaux</span>, icon: <FormatPaintIcon style={{ color: "white" }} fontSize="large" />, link: "/form2" },
    { label: <span style={{ color: "white", fontSize:"18px"  }}>Déconnexion</span>, icon: <ExitToAppIcon style={{ color: "white" }} fontSize="large" />, onClick: handleLogout },
    {
      label: <span style={{ color: "white", fontSize:"18px"  }}>Administration</span>,
      icon: <PeopleAltIcon style={{ color: "white" }} fontSize="large" />,
      link: "/admin",
      id: "admin-button",
      style: !isAdmin ? { display: "none" } : {}
    },
  ];

  const drawerWidth = 250;
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex !important",
      margin: "0px !important",
      padding: "0px !important",
      position: "relative",
      zIndex: "999"
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: "#121212 !important",
    },
    logo: {
      display: "flex !important",
      justifyContent: "center !important",
      alignItems: "center !important",
      height: "80px !important",
      width: "auto !important",
    },
    logoImg: {
      height: "80px !important",
      width: "auto !important",
    },
    menuItem: {
      marginBottom: "10px !important",
      "&:hover": {
        backgroundColor: "#f700c0 !important",
      },
    },
    menuButton: {
      color: "black !important",
      border: "solid 1px black !important",
      backgroundColor: "white !important",
      borderRadius:"15px !important"
    },
    menuButtonContainer: {
      position: "absolute",
      top: 0,
      left: 15,
      padding: "10px",
      zIndex: "1000"
    }
  }));  

  const classes = useStyles();
  const navigate = useNavigate();

  function handleLogout() {
    localStorage.clear();
    auth.signOut().then(() => {
      navigate("/");
    });
  }

  function handleDrawerToggle() {
    setMenuOpen(!menuOpen); // inverser la valeur de menuOpen
  }

  return (
    <div className={classes.root}>
      <div className={classes.menuButtonContainer}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          {menuOpen ? <CloseIcon fontSize="large"/> : <MenuIcon fontSize="large"/>}
        </IconButton>
      </div>
      <Drawer
        className={classes.drawer}
        variant="temporary"
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleDrawerToggle}
        open={menuOpen}
      >
        <div className={classes.logo}>
          <img src="/komk.png" alt="Logo" className={classes.logoImg} />
        </div>
        <List>
          {menuItems.map((item, index) => (
            <ListItemButton
              key={index}
              component={item.link ? Link : "button"}
              to={item.link}
              className={classes.menuItem}
              onClick={item.onClick}
              id={item.id}
              style={{ ...item.style, width: "100%" }}
            >
              <ListItemIcon className={classes.icon}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default SideMenu;