import React, { useState, useEffect } from "react";
import { Review1 } from "./stepForm/Review1.jsx";
import { Question } from "./stepForm/Question.jsx";

const defaultData = {
  question: "",
};

const steps = [
  { id: "question" },
  { id: "review1" },
];

export const MultiStepForm1 = () => {
  const [formData, setFormData] = useState(defaultData);
  const [step, setStep] = useState(0);
  const navigation = {
    previous: () => {
      setStep((s) => Math.max(0, s - 1));
    },
    next: () => {
      setStep((s) => Math.min(steps.length - 1, s + 1));
    },
    goToStep: (index) => {
      setStep(Math.min(steps.length - 1, index));
    },
  };

  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    const savedData = localStorage.getItem("formData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormData((prevFormData) => {
        const newFormData = { ...prevFormData };
        Object.keys(parsedData).forEach((fieldName) => {
          if (newFormData.hasOwnProperty(fieldName)) {
            newFormData[fieldName] = parsedData[fieldName];
          }
        });
        return newFormData;
      });
    }
  }, []);

  const props = { formData, setFormData, navigation };

  switch (steps[step].id) {
    case "question":
      return <Question {...props} />;
    case "review1":
      return <Review1 {...props} />;
    default:
      return (
        <div>
          <h1>Materiaux</h1>
        </div>
      );
  }
};
