import React, { useState, useEffect } from "react";
import {
    auth,
    createUserWithEmailAndPassword,
    doc,
    db,
    setDoc,
    deleteDoc,
} from "../firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { makeStyles } from "@mui/styles";
import {
    Typography,
    TextField,
    Button,
    Paper,
    Grid,
    Container,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
} from "@mui/material";
import { format, addDays, parse } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: "30px !important",
        padding: "35px !important",
        borderRadius: "5px !important",
    },
    form: {
        width: "100% !important",
        marginTop: "4px !important",
    },
    submit: {
        marginTop: "25px !important",
    },
    table: {
        minWidth: 650,
    },
    tableCell: {
        fontWeight: "bold !important",
    },
    actionIcon: {
        marginLeft: "1px !important",
        cursor: "pointer !important",
    },
    title: {
        fontWeight: "600 !important",
        marginBottom: "15px !important",
    },
    text: {
        fontWeight: "400 !important",
        marginTop: "10px !important",
        fontSize: "13px !important",
    },
    green: {
        backgroundColor: '#66cc99 !important',
    },
    orange: {
        backgroundColor: '#f27935 !important',
    },
    red: {
        backgroundColor: '#f64747 !important',
    },
}));

export const Admin = () => {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [error, setError] = useState("");
    const [users, setUsers] = useState([]);
    const [showPassword, setShowPassword] = useState(false); 

    const handleCreateUser = async (e) => {
        e.preventDefault();
        try {
            const expirationDate = addDays(new Date(), 365); // Ajouter 365 jours à la date actuelle
            const formattedExpirationDate = format(expirationDate, "yyyy/MM/dd");
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            );
            await setDoc(doc(db, "users", userCredential.user.uid), {
                email: email,
                password: password,
                username: username,
                expirationDate: formattedExpirationDate,
            });
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "users"), (snapshot) => {
            const usersData = snapshot.docs.map((doc) => {
                const data = doc.data();
                const expirationDate = parse(data.expirationDate, "yyyy/MM/dd", new Date());
                const daysRemaining = Math.ceil(
                    (expirationDate.getTime() - new Date().getTime()) /
                    (1000 * 60 * 60 * 24)
                );
                let color = "";
                if (daysRemaining >= 60) {
                    color = "green";
                } else if (daysRemaining >= 14) {
                    color = "orange";
                } else {
                    color = "red";
                }

                let formattedExpirationDate = "";
                if (expirationDate instanceof Date && !isNaN(expirationDate)) {
                    formattedExpirationDate = format(expirationDate, "yyyy/MM/dd");
                } else {
                    console.error("Invalid expirationDate value: ", expirationDate);
                    formattedExpirationDate = "N/A";
                }

                return {
                    id: doc.id,
                    ...data,
                    expirationDate: formattedExpirationDate,
                    daysRemaining,
                    userColor: color,
                };
            });
            setUsers(usersData);
        });
        return () => {
            unsubscribe();
        };
    }, []);

    const handleDeleteUser = async (userId) => {
        try {
            await deleteDoc(doc(db, "users", userId));
        } catch (error) {
            console.error(error);
        }
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Container maxWidth="lg">
            <Paper className={classes.paper}>
                <Typography className={classes.title} component="h1" variant="h5">
                    Créer un nouveau utilisateur
                </Typography>
                <form className={classes.form} onSubmit={handleCreateUser}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="username"
                                label="Nom d'utilisateur"
                                name="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Adresse email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Mot de passe"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Créer un utilisateur
                    </Button>
                </form>
                {error && <Typography color="error">{error}</Typography>}
            </Paper>
            <Paper className={classes.paper}>
                <Typography className={classes.title} component="h1" variant="h5">
                    Historique des comptes utilisateurs
                </Typography>
                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableCell}>Nom d'utilisateur</TableCell>
                                <TableCell className={classes.tableCell}>Adresse email</TableCell>
                                <TableCell className={classes.tableCell}>
                                    Mot de passe
                                    {showPassword ? (
                                        <IconButton onClick={handleTogglePassword}>
                                            <VisibilityOffIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton onClick={handleTogglePassword}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    )}
                                </TableCell>
                                <TableCell className={classes.tableCell}>Jours restants</TableCell>
                                <TableCell className={classes.tableCell}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user) => (
                                <TableRow key={user.id} className={clsx(classes.tableRow, { [classes.green]: user.userColor === 'green', [classes.orange]: user.userColor === 'orange', [classes.red]: user.userColor === 'red' })}>                                    
                                    <TableCell>{user.username}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{showPassword ? user.password : "••••••••"}</TableCell>
                                    <TableCell>{user.daysRemaining}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => handleDeleteUser(user.id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography className={classes.text} component="h1" variant="h5">
                    Pour rappel : le tableau ci-dessus est seulement un historique des comptes utilisateurs qui ont été créés. Il ne permet pas de supprimer ou de désactiver l'accès d'un utilisateur à l'application. Pour réaliser cela, rendez-vous sur <a href="https://console.firebase.google.com/project/matrice-a022f/authentication/users">la plateforme Firebase</a> section authentification.
                    <br />
                    Code couleur : Vert validité au-dessus de 2 mois, Orange validité en dessous de 2 mois et Rouge validité en dessous de 2 semaines.
                </Typography>
            </Paper>
        </Container>
    );
};