import React from "react";
import {
  Button,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  body: {
    marginLeft: "50px",
    marginRight: "50px",
    textAlign: "center",
  },
  headerContainer: {
    padding: 0,
    margin: 0,
  },
  header: {
    backgroundImage: `url(banner.png) !important`, // add background image
    width: "auto !important",
    height: "350px !important",
    backgroundSize: "cover !important", // adjust image size to cover entire header
    backgroundPosition: "0% 35% !important",
    display: "flex !important", // use flexbox to center header content
    alignItems: "center !important",
    justifyContent: "center !important",
    textAlign: "center !important",
    position: "relative !important",
    marginBottom: "50px !important",
  },
  headerOverlay: {
    position: "absolute !important",
    top: "0 !important",
    left: "0 !important",
    width: "100% !important",
    height: "100% !important",
    backgroundColor: "rgba(0, 0, 0, 0.3) !important",
    zIndex: "1 !important",
  },
  headerTitle: {
    padding: "0px !important",
    margin: "0px !important",
    position: "relative !important",
    zIndex: "2 !important",
  },
  title: {
    fontSize: "3rem !important",
    fontWeight: "800 !important",
    marginBottom: "10px !important",
    color: "black !important",
  },
  subtitle: {
    fontSize: "1.2rem !important",
    fontWeight: "500 !important",
    marginBottom: "0px !important",
    color: "black !important",
  },
  card: {
    height: "100% !important",
    paddingTop: "20px !important",
    paddingBottom: "20px !important",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2) !important", // modification de l'ombre
    transition: "transform 0.3s ease-out !important", // modification de la transition pour un effet plus doux
    "&:hover": {
      transform: "translateY(-5px) !important", // modification de l'effet de soulevement pour un effet plus léger
      cursor: "pointer !important",
    },
  },
  cardMedia: {
    maxWidth: "65px !important",
    height: "auto !important",
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
  cardContent: {
    textAlign: "center !important",
    paddingTop: "20px !important",
  },
  cardTitle: {
    paddingBottom: "10px !important",
  },
  titleGrid: {
    fontSize: "1.1rem !important",
    fontWeight: "800 !important",
    color: "#e15a82 !important",
  },
  subtitleGrid: {
    fontSize: "2.2rem !important",
    fontWeight: "700 !important",
    paddingBottom: "5px !important",
  },
  bodyGrid: {
    fontSize: "1rem !important",
    fontWeight: "400 !important",
    width: "70% !important",
    paddingBottom: "20px !important",
  },
  Grid: {
    marginBottom: "70px !important",
  },
  containerInfo: {
    display: "flex !important",
    justifyContent: "space-between !important",
    marginBottom: "40px !important",
    marginTop: "80px !important",
  },
  GridInfo: {
    textAlign: "left !important",
  },
  cardInfoTitle: {
    backgroundColor: "#eb4cbb !important",
    fontSize: "18px !important",
    color: "white !important",
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
    paddingLeft: "15px !important",
    borderRadius: "2px !important",
  },
  cardInfoText: {
    fontSize: "15px !important",
    paddingLeft: "15px !important",
    paddingTop: "3px !important",
    paddingBottom: "3px !important",
    fontWeight: "400 !important",
    color: "black !important",
    textDecoration: "none !important",
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  //const userEmail = localStorage.getItem("email");
  const navigate = useNavigate();

  const handleGoToForm = () => {
    navigate("/form");
  };

  return (
    <div style={{ margin: '0px', padding: '0px', width: '100%', height: 'auto' }}>
      <div className={classes.headerContainer}>
        <div className={classes.header}></div>
      </div>
      <div className={classes.body}>
        <Typography variant="h2" gutterBottom className={classes.title}>
          Bienvenue dans la matrice kom'K
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          className={classes.subtitle}
        >
          Elle vous permet de trouver la matière adaptée à votre projet en quelques clics.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          className={classes.subtitle}
        >
          À la fin du questionnaire vous aurez la fiche technique, le PV au feu et parfois l'application pour la pose.
        </Typography>
        <Button onClick={handleGoToForm} variant="contained" style={{ marginTop: "25px", backgroundColor: "#ec4cbc" }}>
          À vous de jouer !
        </Button>
        <div className={classes.containerInfo}>
          <Grid container spacing={5} className={classes.GridInfo}>
            <Grid item xs={12} md={4} className={classes.CardInfo}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.cardInfoTitle}
              >
                SOCIÉTÉ
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.cardInfoText}
              >
                kom'K
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.cardInfoText}
              >
                SIRET : 90505950700012
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.cardInfoText}
              >
                Enregistré au RCS de Marseille
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.cardInfoText}
              >
                NDA : 93131987513
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} className={classes.CardInfo}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.cardInfoTitle}
              >
                SERVICES
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
              >
                <a href="http://galerie.kom-k.fr/Catalogue%20de%20formation.pdf" className={classes.cardInfoText}>Catalogue</a>
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
              >
                <a href="http://galerie.kom-k.fr/Livret%20stagiaire%20Kom'k.pdf" className={classes.cardInfoText} >Livret stagiaire</a>
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
              >
                <a href="http://galerie.kom-k.fr/CONDITIONS%20GENERALES%20DE%20VENTE.pdf" className={classes.cardInfoText}>CGV</a>
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
              >
                <a href="https://galerie.kom-k.fr/Re%cc%80glement%20inte%cc%81rieur%20Formation%20pre%cc%81sentiel.pdf" className={classes.cardInfoText}>Réglement intérieur</a>
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} className={classes.CardInfo}>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.cardInfoTitle}
              >
                CONTACT
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.cardInfoText}
              >
                8 Avenue Roger Salengro, 13830, Roquefort la Bedoule
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.cardInfoText}
              >
                Katia.ferrandi@kom-k.fr
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                className={classes.cardInfoText}
              >
                06 16 92 01 35
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
