import React, { useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { makeStyles } from "@mui/styles";
import products from "./products.json";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "600 !important",
    marginBottom: "15px !important",
  },
  formControl: {
    minWidth: 130,
  },
  inputLabel: {
    whiteSpace: 'nowrap !important',
  },
}));

export const Review1 = ({ formData }) => {
  const classes = useStyles();
  const { question } = formData;

  const [perPage, setPerPage] = useState(20);
  
  const generateCustomResponse = () => {
    let filteredProducts = products.data.filter(
      (product) => product.matiere === question
    );

    if (perPage === "all") {
      const products = filteredProducts.map((product) => ({
        name: product.nom,
        id: product.id,
        supportcm: product.supportcm,
      }));
      const uniqueProducts = products.reduce((acc, curr) => {
        if (!acc[curr.name]) {
          acc[curr.name] = curr;
        }
        return acc;
      }, {});
      return { products: Object.values(uniqueProducts) };
    } else {
      const displayedProducts = filteredProducts.map((product) => ({
        name: product.nom,
        id: product.id,
        supportcm: product.supportcm,
      }));

      const uniqueProducts = displayedProducts.reduce((acc, curr) => {
        if (!acc[curr.name]) {
          acc[curr.name] = curr;
        }
        return acc;
      }, {});

      return { products: Object.values(uniqueProducts) };
    }
  };

  const [pdfFileNames, setPdfFileNames] = useState([]);
  const [error, setError] = useState(false);

  const handleRestartForm = () => {
    window.location.reload();
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography className={classes.title} component="h1" variant="h5">
                Résultat de la matrice 
              </Typography>
              {generateCustomResponse().products.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table aria-label="Produits">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nom du produit</TableCell>
                        <TableCell>Support en cm</TableCell>
                        <TableCell>Documentation</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {generateCustomResponse().products.map((product) => (
                        <TableRow key={product.id}>
                          <TableCell>{product.name}</TableCell>
                          <TableCell>{product.supportcm}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                const pdfFiles = require.context("../../../public/pdf", true, /\.pdf$/);
                                let matchingFileNames = [];
                                pdfFiles.keys().forEach((fileName) => {
                                  const regex = /(\d+)\s+à\s+(\d+)|(\d+)\.pdf$/;
                                  const matches = fileName.match(regex);
                                  if (matches) {
                                    const startId = parseInt(matches[1]);
                                    const endId = parseInt(matches[2]);
                                    if (product.id >= startId && product.id <= endId) {
                                      matchingFileNames.push(fileName);
                                    }
                                  }
                                });
                                if (matchingFileNames.length > 0) {
                                  setPdfFileNames(matchingFileNames);
                                  setError(false);
                                } else {
                                  setError(true);
                                }
                              }}
                            >
                              Voir PDF
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="body1">{generateCustomResponse().name}</Typography>
              )}
              {pdfFileNames.length > 0 ? (
                <div>
                  {pdfFileNames.map((fileName) => (
                    <iframe
                      key={fileName}
                      src={`/pdf/${fileName}`}
                      type="application/pdf"
                      title={fileName}
                      style={{
                        width: '100%',
                        height: '1000px',
                        margin: '20px 0',
                      }}
                    />
                  ))}
                </div>
              ) : error ? (
                <Typography variant="body1" style={{ marginTop: '20px', fontSize: '17px', fontWeight: '600', color: '#cc0000' }}>
                  Aucun fichier PDF n'a été trouvé pour ce produit.
                </Typography>
              ) : (
                <Typography variant="body1"></Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
