import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { MultiStepForm } from "./components/MultiStepForm";
import { MultiStepForm1 } from "./components/MultiStepForm1";
import { HomePage } from "./components/HomePage.jsx";
import { SideMenu } from "./components/SideMenu.jsx";
import { Review } from "./components/stepForm/Review.jsx";
import { Login } from "./components/Login.jsx";
import { Admin } from "./components/Admin.jsx"
import { auth } from './firebase';
import { useState, useEffect } from "react";
import { NotFoundPage } from "./components/NotFoundPage.jsx";
import { ThemeProvider, createTheme } from "@mui/material/styles";

export const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState(localStorage.getItem("email"));
  const [isAdmin, setIsAdmin] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#ec4cbc"
      }
    }
  });

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUserEmail(user.email);
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        setIsAdmin(false);
      }
    });
    const isAdmin = localStorage.getItem('isAdmin');
    if (isAdmin) {
      setIsAdmin(isAdmin === 'true');
    }
  }, [userEmail]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div style={{ display: "flex" }}>
          {isAuthenticated && <SideMenu isAdmin={isAdmin} />}
          <Routes>
            <Route
              path="/"
              exact
              element={
                !isAuthenticated ? (
                  <Login setIsAdmin={setIsAdmin} />
                ) : (
                  <Navigate to="/home" />
                )
              }
            />
            <Route path="/form" element={isAuthenticated && <MultiStepForm />} />
            <Route path="/form2" element={isAuthenticated && <MultiStepForm1 />} />
            <Route path="/review" element={isAuthenticated && <Review />} />
            <Route path="/home" element={isAuthenticated && <HomePage />} />
            <Route
              path="/admin"
              element={
                isAuthenticated && isAdmin ? <Admin /> : <Navigate to="/home" />
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
};
