import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { TextField, IconButton, InputAdornment, Link, Paper, Box, Grid, Typography, CssBaseline, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://kom-k.fr/">
                kom'K
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export const Login = ({ setIsAdmin }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const handleCloseError = () => {
        setShowError(false);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            setError("Veuillez remplir tous les champs avant de vous connectez");
            setShowError(true);
            setTimeout(() => setShowError(false), 5000);
            return;
        }
        try {
            await signInWithEmailAndPassword(auth, email, password);
            setIsAuthenticated(true);
            localStorage.setItem("email", email);
            if (email === "katia.ferrandi@orange.fr") {
                setIsAdmin(true);
                localStorage.setItem("isAdmin", true);
            }
        } catch (error) {
            setError("Adresse e-mail ou le mot de passe est incorrect, Veuillez réessayer");
            setShowError(true);
            setTimeout(() => setShowError(false), 5000);
        }
    };

    useEffect(() => {
        if (auth.currentUser) {
            setIsAuthenticated(true);
            navigate('/home');
        }
    }, [navigate]);

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(imageConnexion.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: '0% 20%',

                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <Box
                        sx={{
                            padding: '20px',
                        }}
                    >
                        {showError && (
                            <div style={{ backgroundColor: "#f8d7da", color: "#721c24", padding: "10px", borderRadius: "5px", marginBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography color="error">{error}</Typography>
                                <button onClick={handleCloseError} style={{ border: "none", background: "none", cursor: "pointer", fontSize: "1.5rem", fontWeight: "bold", color: "#721c24" }}>×</button>
                            </div>
                        )}
                        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                            <img src="logo2.png" alt="logo kom K" style={{ width: 'auto', height: '80px' }} />
                        </div>
                        <Box sx={{ mt: 0 }}>
                            <form onSubmit={handleLogin}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Adresse mail"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    InputProps={{
                                        style: { borderRadius: 15 }
                                    }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Mot de passe"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    InputProps={{
                                        style: { borderRadius: 15 },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    style={{ backgroundColor: "#ec4cbc" }}
                                >
                                    Se connecter
                                </Button>
                            </form>
                        </Box>
                        <Box>
                            <Copyright />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};