import React, { useState, useEffect } from "react";
import { Review } from "./stepForm/Review.jsx";
import { Question1 } from "./stepForm/Question1.jsx";
import { Question2 } from "./stepForm/Question2.jsx";
import { Question3 } from "./stepForm/Question3.jsx";
import { Question4 } from "./stepForm/Question4.jsx";
import { Question5 } from "./stepForm/Question5.jsx";

const defaultData = {
  question1: "",
  question2: "",
  question3: "",
  question4: "",
  question5: "",
};

const steps = [
  { id: "question1" },
  { id: "question2" },
  { id: "question3" },
  { id: "question4" },
  { id: "question5" },
  { id: "review" },
];

export const MultiStepForm = () => {
  const [formData, setFormData] = useState(defaultData);
  const [step, setStep] = useState(0);
  const navigation = {
    previous: () => {
      setStep((s) => Math.max(0, s - 1));
    },
    next: () => {
      setStep((s) => Math.min(steps.length - 1, s + 1));
    },
    goToStep: (index) => {
      setStep(Math.min(steps.length - 1, index));
    },
  };

  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    const savedData = localStorage.getItem("formData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormData((prevFormData) => {
        const newFormData = { ...prevFormData };
        Object.keys(parsedData).forEach((fieldName) => {
          if (newFormData.hasOwnProperty(fieldName)) {
            newFormData[fieldName] = parsedData[fieldName];
          }
        });
        return newFormData;
      });
    }
  }, []);

  const props = { formData, setFormData, navigation };

  switch (steps[step].id) {
    case "question1":
      return <Question1 {...props} />;
    case "question2":
      return <Question2 {...props} />;
    case "question3":
      return <Question3 {...props} />;
    case "question4":
      return <Question4 {...props} />;
    case "question5":
      return <Question5 {...props} />;
    case "review":
      return <Review {...props} />;
    default:
      return (
        <div>
          <h1>Matrice</h1>
        </div>
      );
  }
};
