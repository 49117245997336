import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import "./style.css";

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: "4px !important",
    position: "fixed !important",
    bottom: 0,
    right: 0,
    height: "20% !important",
    width: "auto !important",
    zIndex: 1,
    borderRadius: 0,
    animation: "levitate 3s ease-in-out infinite !important",
  },
  bubble: {
    position: "fixed !important",
    bottom: 20,
    right: 150,
    backgroundColor: "white !important",
    padding: "12px !important",
    borderRadius: "10px !important",
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.2) !important",
    display: "flex !important",
    alignItems: "center !important",
    maxWidth: "50% !important",
    zIndex: 1,
    animation: "float 3s ease-in-out infinite !important",
  },
  characterContainer: {
    position: "relative !important",
    width: "100vw !important",
    height: "100vh !important",
    overflow: "hidden !important",
  },
}));

export const CharacterAnimation = ({ characterImage, characterText }) => {
  const classes = useStyles();

  return (
    <div className={classes.characterContainer}>
      <div className={classes.bubble}>
        <Typography variant="body1" gutterBottom>
          {characterText}
        </Typography>
      </div>
      <Avatar
        alt="Character"
        src={characterImage}
        className={classes.avatar}
      />
    </div>
  );
};






