import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { CharacterAnimation } from "../CharacterAnimation.jsx";
import "../style.css";

import products from "./products.json";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: "50px !important",
    paddingBottom: "50px !important",
  },
  image: {
    maxWidth: "100% !important",
    borderRadius: "10px !important",
  },
  question: {
    fontSize: "35px !important",
    fontWeight: "800 !important",
    lineHeight: "45px !important",
  },
  radioGroup: {
    marginTop: "20px !important",
    marginBottom: "15px !important",
  },
  label: {
    fontSize: "17px !important",
    marginLeft: "5px !important",
    fontWeight: "400 !important",
  },
  containerQuestion: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
}));

export const Question1 = ({ formData, setFormData, navigation }) => {
  const { question1 } = formData;
  const [isAnswered, setIsAnswered] = useState(false);

  const classes = useStyles();

  const handleAnswered = () => {
    setIsAnswered(true);
  };

  const handleNext = () => {
    navigation.next();
  };

  const uniqueLieuPose = [...new Set(products.data.map((item) => item.lieupose))];

  useEffect(() => {
    setIsAnswered(question1 !== "");
  }, [question1]);

  return (
    <Container maxWidth="md" className={classes.containerQuestion}>
      <div className={classes.root}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={10} sm={6}>
            <img
              src="question1.jpg"
              alt="Question 1"
              className={classes.image}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              className={classes.question}
            >
              Où est-ce que vous souhaitez l'appliquer ?
            </Typography>
            <RadioGroup
              className={classes.radioGroup}
              aria-label="question1"
              name="question1"
              value={question1}
              onChange={(e) => {
                setFormData({ ...formData, question1: e.target.value });
                handleAnswered();
              }}
            >
              {uniqueLieuPose.map((lieupose) => (
                <FormControlLabel
                  key={lieupose}
                  classes={{ label: classes.label }}
                  value={lieupose}
                  control={<Radio />}
                  label={lieupose}
                />
              ))}
            </RadioGroup>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              style={{ marginTop: "1rem" }}
              disabled={!isAnswered}
              onClick={handleNext}
            >
              Suivant
            </Button>
          </Grid>
        </Grid>
      </div>
      {/*<CharacterAnimation
        characterImage="/character2.png"
        characterText="Merci d'utiliser la matrice kom'K, tu peux commencer à répondre à mes questions"
      />*/}
    </Container>
  );
};